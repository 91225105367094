import { fetchScheduledReportList } from '../../../services/scheduledReport';

const moment = require('moment');
const _ = require('lodash');

export default {
  name: 'scheduledReportList',
  props: {
    channelType: String,
    channel: String
  },
  data() {
    return {
      creatingScheduledReport: false,
      fetchingData: false,
      scheduledReportList: [],

      searchFilter: '',
      pageOptions: {
        pageSize: 20,
        pageNumber: 1,
        total: null
      }
    };
  },

  methods: {
    onCreateScheduledReportClick() {
      this.creatingScheduledReport = true;
    },

    getScheduledReportDetailsPageLink(scheduledReportId) {
      return '/scheduled-reports/' + scheduledReportId;
    },

    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.pageNumber = 1;
      this.fetchScheduledReportList();
    },

    async fetchScheduledReportList() {
      try {
        this.fetchingData = true;

        let params = {
          search: this.searchFilter,
          pageOptions: this.pageOptions
        };

        let result = await fetchScheduledReportList(params, this);
        this.scheduledReportList = result.data.list;
        this.pageOptions.total = result.data.total;

        let hourList = this.get24HoursList();
        for (var i = 0; i < this.scheduledReportList.length; i++) {
          let report = this.scheduledReportList[i];

          // Decide Timing
          let time = report.repeat_info[report.repeat_type];
          if (report.repeat_type != 'daily') time = report.repeat_info[report.repeat_type].time;
          report.timeText = _.find(hourList, (hr) => {
            return hr.key == time;
          });

          // Decide Updateor
          this.updatorName = null;
          report.updated_at = report.updated_at && report.updated_at != 'N/A' ? this.getDisplayTime(report.updated_at) : 'N/A';
          if (report.updator_first_name) {
            this.updatorName = report.updator_first_name + ' ' + report.updator_last_name;
          }
          report.updator_name = this.updatorName;
        }
        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong.');
      }
    },

    getDisplayTime(date) {
      if (!date) {
        return `N/A`;
      }
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm:ss A');
    }
  },

  mounted() {
    // Modify Query-String
    const query = this.$route.query;
    this.searchFilter = query.search ? query.search : this.searchFilter;
    this.statusFilter = query.statusFilter ? JSON.parse(query.statusFilter) : this.statusFilter;
    this.pageOptions = query.pageOptions ? JSON.parse(query.pageOptions) : this.pageOptions;

    this.fetchScheduledReportList(true);
  }
};
