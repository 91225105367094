<template>
  <div id="scheduledReportListPage">
    <div class="max-w-screen-xl m-auto">
      <!-- Header -->
      <div class="header bg-white px-4 pt-3 border-b mt-3 mb-3 border rounded-md overflow-hidden">
        <el-row>
          <el-col :span="18">
            <h2 class="text-3xl font-bold leading-7 text-gray-900 pt-1">
              Scheduled Reports
              <div class="menuBetaTag">BETA</div>
            </h2>
            <div class="pt-0.5 pb-3 text-sm text-gray-500">Get daily, weekly, monthly campaign and journey performance reports on email.</div>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <div class="-mt-3">
              <a target="_blank" href="http://help.growlytics.in/en/articles/5795013-scheduling-campaign-reports">
                <el-button class="py-0 -mt-2 display-block" type="text"> How Scheduled Report Works? <i class="el-icon-top-right"></i> </el-button>
              </a>
              <router-link :to="{ path: '/scheduled-reports/create' }">
                <el-button size="small" type="primary" :loading="creatingScheduledReport" @click="onCreateScheduledReportClick"> <i class="el-icon-circle-plus-outline el-icon-right"></i> &nbsp; Create Scheduled Report </el-button>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- Loader -->
      <div class="w-full px-10 h-80 border rounded-md overflow-hidden" v-if="fetchingData && scheduledReportList" v-loading="true"></div>

      <!-- Campaign List -->
      <div class="scheduledReportList" v-if="!fetchingData && scheduledReportList && scheduledReportList.length > 0">
        <!-- Header Row -->
        <div class="scheduledReportItem headerRow">
          <el-row style="width: 100%">
            <!-- General Details -->
            <el-col :span="10">Report Name</el-col>

            <!-- Frequency -->
            <el-col :span="4">Schedule</el-col>

            <!-- When -->
            <el-col :span="4">Total Subscribers</el-col>

            <!-- Created At -->
            <el-col :span="6" class="createdAt"> Last Updated </el-col>
          </el-row>
        </div>

        <!-- ScheduledReport List -->
        <div class="scheduledReportItem" v-for="(scheduledReport, index) in scheduledReportList" :key="index">
          <el-row style="width: 100%">
            <!-- General Details -->
            <el-col :span="10">
              <router-link class="detailsLink" :to="getScheduledReportDetailsPageLink(scheduledReport.id)">
                {{ scheduledReport.name }}
              </router-link>
            </el-col>

            <!-- Frequency -->
            <el-col :span="4"
              >{{ scheduledReport.repeat_type.toUpperCase() }} <br />
              AT {{ scheduledReport.timeText ? scheduledReport.timeText.label : '--' }}
            </el-col>

            <!-- When -->
            <el-col :span="4">{{ scheduledReport.total_subscribers }}</el-col>

            <!-- Created At -->
            <el-col :span="6" class="createdAt">
              <div>{{ scheduledReport.updated_at }}</div>
              <div v-if="scheduledReport.updator_name">By {{ scheduledReport.updator_name }}</div>
            </el-col>
          </el-row>
        </div>

        <!-- Bottom Pagination -->
        <div class="pagination pagination-bottom">
          <div class="totalCount">Total {{ pageOptions.total }} ScheduledReports</div>
          <el-pagination @current-change="fetchScheduledReportList" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
        </div>
      </div>

      <!-- No Data -->
      <div class="noDataDiv border rounded-md bg-white" v-if="!fetchingData && scheduledReportList && scheduledReportList.length == 0">
        <div class="msg">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64" height="64" viewBox="0 0 172 172" style="fill: #000000; margin: auto">
            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#9ba3af">
                <path
                  d="M129,87.34375c-13.64873,-0.03774 -26.44521,6.63148 -34.23216,17.84098c-7.78694,11.2095 -9.57073,25.52894 -4.77185,38.30627l20.02878,-20.02881c0.7558,-0.75617 1.7811,-1.18102 2.85023,-1.18102c1.06912,0 2.09443,0.42485 2.85023,1.18102l10.58727,10.58738l14.45582,-14.45582h-6.39332c-2.2264,0 -4.03125,-1.80485 -4.03125,-4.03125c0,-2.2264 1.80485,-4.03125 4.03125,-4.03125h16.125c2.22315,0.00783 4.02342,1.8081 4.03125,4.03125v16.125c0,2.2264 -1.80485,4.03125 -4.03125,4.03125c-2.2264,0 -4.03125,-1.80485 -4.03125,-4.03125v-6.39332l-17.30602,17.30605c-0.7558,0.75617 -1.7811,1.18102 -2.85023,1.18102c-1.06912,0 -2.09443,-0.42485 -2.85023,-1.18102l-10.58727,-10.5873l-19.10858,19.10858c10.64431,16.94707 31.84029,23.92319 50.46905,16.61062c18.62876,-7.31257 29.41914,-26.84468 25.69262,-46.50728c-3.72652,-19.6626 -20.91548,-33.89197 -40.92809,-33.8811z"
                ></path>
                <path
                  d="M91.375,170.65625c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125h-77.9375c-2.22539,-0.00243 -4.02882,-1.80586 -4.03125,-4.03125v-145.125c0.00243,-2.22539 1.80586,-4.02882 4.03125,-4.03125h30.90625v9.40625c0.00727,6.67618 5.41757,12.08648 12.09375,12.09375h26.875c6.67618,-0.00727 12.08648,-5.41757 12.09375,-12.09375v-9.40625h30.90625c2.22539,0.00243 4.02882,1.80586 4.03125,4.03125v61.8125c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-61.8125c-0.00727,-6.67618 -5.41757,-12.08648 -12.09375,-12.09375h-112.875c-6.67618,0.00727 -12.08648,5.41757 -12.09375,12.09375v145.125c0.00727,6.67618 5.41757,12.08648 12.09375,12.09375zM87.34375,18.8125c-0.00243,2.22539 -1.80586,4.02882 -4.03125,4.03125h-26.875c-2.22539,-0.00243 -4.02882,-1.80586 -4.03125,-4.03125v-9.40625h34.9375z"
                ></path>
                <path d="M48.375,38.96875c-14.10052,0 -25.53125,11.43073 -25.53125,25.53125c0,14.10052 11.43073,25.53125 25.53125,25.53125c14.10052,0 25.53125,-11.43073 25.53125,-25.53125c-0.01601,-14.09388 -11.43737,-25.51524 -25.53125,-25.53125zM65.84375,64.5c-0.00164,3.23481 -0.90818,6.40465 -2.61714,9.15118l-10.82036,-10.82036v-15.31125c7.86654,1.87232 13.42328,8.89416 13.4375,16.98043zM44.34375,47.51957v12.94918h-12.94918c1.53392,-6.41025 6.53893,-11.41526 12.94918,-12.94918zM31.39457,68.53125h15.31125l10.82036,10.82036c-4.7642,2.95662 -10.66076,3.4378 -15.84127,1.29269c-5.18052,-2.1451 -9.01086,-6.65392 -10.29034,-12.11305z"></path>
                <path d="M86,52.40625h21.5c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125h-21.5c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125c0,2.2264 1.80485,4.03125 4.03125,4.03125z"></path>
                <path d="M86,84.65625h21.5c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125h-21.5c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125c0,2.2264 1.80485,4.03125 4.03125,4.03125z"></path>
                <path d="M86,68.53125h21.5c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125h-21.5c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125c0,2.2264 1.80485,4.03125 4.03125,4.03125z"></path>
                <path d="M69.875,103.46875h-43c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125c0,2.2264 1.80485,4.03125 4.03125,4.03125h43c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125z"></path>
                <path d="M69.875,119.59375h-43c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125c0,2.2264 1.80485,4.03125 4.03125,4.03125h43c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125z"></path>
                <path d="M69.875,135.71875h-43c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125c0,2.2264 1.80485,4.03125 4.03125,4.03125h43c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125z"></path>
              </g>
            </g>
          </svg>
          <br />
          No Reports Scheduled
        </div>

        <router-link :to="{ path: '/scheduled-reports/create' }">
          <el-button class="createScheduledReportButton" :loading="creatingScheduledReport" type="primary"> Create Scheduled Report </el-button>
        </router-link>

        <div class="couponFeature" style="width: 90%">
          <el-row :gutter="40" class="featureList mt-20">
            <el-col :span="8">
              <i class="fa fa-calendar"></i>
              <div class="title">Scheduled Daily, Weekly, Monthly, Reports</div>
              <div class="description">Get campaign and journey reports on email on daily, weekly and monthly basis.</div>
            </el-col>
            <el-col :span="8">
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
              <div class="title">Specify Data You Need In Report</div>
              <div class="description">Speciy what all campaigns and journey performance data you need in report.</div>
            </el-col>
            <el-col :span="8">
              <i class="fa fa-users" aria-hidden="true"></i>
              <div class="title">Specify Report Receipients</div>
              <div class="description">Specify what all users will receive the scheduled reports on their email id.</div>
            </el-col>
          </el-row>
        </div>

        <div class="mt-16 mb-3">
          <a target="_blank" href="http://help.growlytics.in/en/articles/5795013-scheduling-campaign-reports">
            <el-button class="py-0 -mt-2 display-block" type="text"> Learn More About Scheduled Reports <i class="el-icon-top-right"></i> </el-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="./scheduledReportList.scss"></style>

<script>
import scheduledReportListComponent from './scheduledReportListComponent';
export default scheduledReportListComponent;
</script>
